<script lang="ts" setup>
import type { ModalState } from "~/types/modal";
import { leadCollectionModalStateKey } from "~/types/injectKeys";

const { modalOpen, toggleModal } = inject<ModalState>(leadCollectionModalStateKey, {
  modalOpen: ref(false),
  toggleModal: () => { },
});
</script>

<template>
  <UModal
    v-model="modalOpen"
    :ui="{
      width: 'sm:max-w-2xl',
      overlay: {
        background: 'bg-text-primary/50',
      },
    }"
    :prevent-close="true"
  >
    <UCard class="clear-first-content-padding">
      <div v-auto-animate>
        <LazyFormContact
          mode="horizontal"
          narrow
        />
      </div>

      <UButton
        class="ring-gray-200 absolute right-4 top-4 rounded-full p-2"
        variant="outline"
        color="gray"
        @click.prevent="toggleModal"
      >
        <IconCross class="size-5" />
      </UButton>
    </UCard>
  </UModal>
</template>

<style>
.clear-first-content-padding > div:first-child {
  padding: 0;
}
</style>
